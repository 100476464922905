import getOperators from "~/services/getOperators";

export const initialState: { [key: string]: any } = {
  app: {
    delete: {
      id: null,
      name: ""
    },
    show_deleted_collection_banner: false
  },
  collection: {
    title: "",
    description: "",
    product_limits: 250,
    product_strategies: [
      {
        condition_value: "",
        id: Date.now(),
        operator: "eq",
        operators: getOperators("number"),
        productStrategy_Id: 1,
        strategyType: "number",
        comparator: "and"
      }
    ],
    is_active: true,
    exclude_products: [],
    pined_products: [],
    sort_strategy_id: 1,
    push_sold_out_to_buttom: false,
    job_status: "Pending",
    direction: "ASC",
    frequency: 720,
    products: {}
  },

  sort_strategies: [],
  product_strategies: [],
  frequencies: []
};
