interface GetOperatorsArgs {
  id: number | string;
  label: string;
  value: string | number | boolean;
}

export const rules_of_text: GetOperatorsArgs[] = [
  { id: 2, label: "Starts with", value: "startsWith" },
  { id: 3, label: "Ends with", value: "endsWith" },
  { id: 4, label: "Equals", value: "like" },
  { id: 5, label: "Contains", value: "contains" },
  { id: 6, label: "Does not contain", value: "doesNotContain" }
];

export const rules_of_bool: GetOperatorsArgs[] = [
  { id: 8, label: "Yes", value: "yes" },
  { id: 9, label: "No", value: "no" }
];

export const rules_of_number: GetOperatorsArgs[] = [
  { id: 11, label: "Equals", value: "eq" },
  { id: 12, label: "Greater than", value: "gt" },
  { id: 13, label: "Less than", value: "lt" },
  { id: 14, label: "Less than or equal to", value: "lte" },
  { id: 15, label: "Greater than or equal to", value: "gte" }
];

export const rules_of_tags: GetOperatorsArgs[] = [
  { id: 17, label: "Has", value: "has_tag" },
  { id: 18, label: "Does not have", value: "has_not_tag" },
  { id: 19, label: "Contains", value: "tag_contains" },
  { id: 20, label: "Does not contain", value: "has_not_contains" },
  { id: 21, label: "Starts with", value: "tag_starts_with" },
  { id: 22, label: "Does not start with", value: "has_not_tag_starts_with" },
  { id: 23, label: "Ends with", value: "tag_ends_with" },
  { id: 24, label: "Does not end with", value: "has_not_tag_ends_with" }
];

export const rules_of_date: GetOperatorsArgs[] = [
  { id: 26, label: "Yesterday", value: "yesterday" },
  { id: 27, label: "Last 24 hours", value: "last_24_hours" },
  { id: 28, label: "Last 3 days", value: "last_3_days" },
  { id: 29, label: "Last week", value: "last_week" },
  { id: 30, label: "Last 7 days", value: "last_7_days" },
  { id: 31, label: "Last month", value: "last_month" },
  { id: 32, label: "Last 2 months", value: "last_2_months" },
  { id: 33, label: "Last 30 days", value: "last_30_days" },
  { id: 34, label: "Last 90 days", value: "last_90_days" },
  { id: 35, label: "Last year", value: "last_year" },
  { id: 36, label: "Last 12 months", value: "last_12_months" },
  { id: 37, label: "Older than 7 days", value: "old_7_days" },
  { id: 38, label: "Older than 30 days", value: "old_30_days" },
  { id: 39, label: "Older than 90 days", value: "old_90_days" }
];

const operatorRules: Record<string, GetOperatorsArgs[]> = {
  text: rules_of_text,
  bool: rules_of_bool,
  number: rules_of_number,
  multi_choose: rules_of_tags,
  date: rules_of_date
};

const notFoundOperators: GetOperatorsArgs[] = [
  { id: 0, label: "No conditions available", value: "" }
];

export default function getOperators(type: string): GetOperatorsArgs[] {
  return operatorRules[type] ?? notFoundOperators;
}
